import Carousel from 'react-bootstrap/Carousel'
import { Link } from "react-router-dom";


const NewArrivals = () => {
    return ( 
        <div className="newarrivals">

            <h2 className="head">NEW ARRIVALS</h2>

            <Carousel indicators={false} wrap={false} slide={true}>
                <Carousel.Item interval={1500}>
            <div className="arrivals">
            
                <Link to ="/">
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileSolid_Denim_Mid_Blue_Dress.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>
                
                <Link to="/">
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileSolid-_Smoke_Grey_Pajama.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>
               
                <Link to="/"> 
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileLooney_Tunes-_Tweety_Pattern_Set.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>

            </div>
                </Carousel.Item>


                <Carousel.Item interval={500}>
            <div className="arrivals">

                <Link to="/"> 
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileTie_Dye-_Grey_Set.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>

                <Link to ="/">
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileDenim_Shirt-_Raw_Blue_Shirts.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>
                
                <Link to="/">
                <img src="https://prod-img.thesouledstore.com/public/theSoul/storage/mobile-cms-media-prod/product-images/New_Arrival_TileMickey_Mouse-Women_Crop_Tops.jpg?format=webp&w=480&dpr=1.0" alt="" />
                </Link>
                
            </div>
                </Carousel.Item>
            </Carousel>
        </div>
     );
}
 
export default NewArrivals;