import React from "react";
import DropList from "./DropList";

function DropElements() {

    const droplists = [
        {
            id: 1,
            drophead: "MEN",
            head: "TOPWEAR",
            item1: "T-Shirts",
            item2: "Polos",
            item3: "Shirts",
            item4: "Tank-Tops & Vests"
        },
        {
            id: 2,
            drophead: "WOMEN",
            head: "TOPWEAR",
            item1: "Women's T-Shirts",
            item2: "Dresses",
            item3: "Shirts",
            item4: "Tank-Tops & Vests"
        },
        {
            id: 3,
            drophead: "ACCESSORIES",
            head: "TOPWEAR",
            item1: "Mugs",
            item2: "Cushion Covers",
            item3: "Customs"
        },
        {
            id: 4,
            drophead: "REDWOLF COLLECTION",
            head: "TOPWEAR",
            item1: "T-Shirts",
            item2: "Polos",
            item3: "Shirts",
            item4: "Tank-Tops & Vests"
        },
        {
            id: 5,
            drophead: "WINTER COLLECTION 2022",
            head: "TOPWEAR",
            item1: "T-Shirts",
            item2: "Polos",
            item3: "Shirts",
            item4: "Tank-Tops & Vests"
        },
        {
            id: 6,
            drophead: "VOUCHERS"
        }
    ]

    const dropList = droplists.map(droplist => <DropList key={droplist.id} droplist={droplist} />) 

    return <div className="dropdowns">{dropList}</div>
}
 
export default DropElements;