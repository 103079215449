
import { useState } from "react";
import { Link } from "react-router-dom";
import './navbar.css';
import Login from "../login/Login";
import DropElements from "./DropElements";

const Navbar = () => {
    const [buttonLogin, setButtonLogin] = useState(false);

    return ( 
        <div className="Navbar">
            <div className="title">
                <Link to="/">
                    <img src="https://www.redwolf.in/image/catalog/rw-logo-gif-transparent-red.gif" className="headerimg" alt="The RedWolf Store" />
                </Link>
            </div>

            <div className="nav1">
                <div className="nav1-links">
                    <div className="search">

                    <button type="submit" className="search_button">
                        <i className="fas fa-search search_icon"></i>
                    </button>
                    <input type="search" className="search_input" placeholder="Search for Products" />
                    
                    </div>
                    <Link to="/">TRACK ORDER</Link>
                    <Link to="/contact">CONTACT US</Link>
                    
                </div>
            </div>

            <div className="nav2">
                <DropElements />
                <div className="nav2-links">
                    <div className="icons" onClick={() => setButtonLogin(true)}>
                        <Link to="/">
                        <i className="far fa-user"></i>
                        </Link> 
                    </div>
                    <Login trigger={buttonLogin} setTrigger={setButtonLogin} />

                    <div className="icons">
                        <Link to="/">
                        <i className="far fa-heart"></i>
                        </Link>
                    </div>

                    <div className="icons">
                        <Link to="/">
                        <i className="far fa-shopping-bag"></i>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
     );
}
 
export default Navbar;