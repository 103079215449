
const FeaturedThemes = () => {
    return ( 
        <div className="featuredthemes">

        <h2 className="head"> FEATURED THEMES </h2>
        
        <div className="circularimg">
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/superhero-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/anime-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/slogan-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/music-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/geek-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/cartoon-t-shirts-india-10.jpg" alt="" />
            </div>
            <div className="theme">
                <img src="https://www.redwolf.in/image/catalog/featured_categories/funky-t-shirts-india-10.jpg" alt="" />
            </div>
        </div>

        </div>
     );
}
 
export default FeaturedThemes;