import { Link } from "react-router-dom";
import './contact.css';

const Contact = () => {
    return ( 
        <div className="contactsection">
            <div className="manageorder">
                <h1>Manage Order Easily</h1>
                <div className="manage-icons">
                    <Link to="/">
                        <i className="far fa-user">My Account</i>
                    </Link>
                    <Link to="/">
                        <i className="far fa-map-marker-alt">Track</i>
                    </Link>
                    <Link to="/">
                        <i className="far fa-undo-alt">Return</i>
                    </Link>
                    <Link to="/">
                        <i className="far fa-times-square">Cancel</i>
                    </Link>
                </div>
            </div>
        </div>
     );
}
 
export default Contact;