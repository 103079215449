import Home from "./components/home/Home";
import Navbar from "./components/menu/Navbar";
import './styles/home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";



const RedWolf = () => {
    return ( 
        <Router>
        <div className="RedWolf">
            <Navbar />
            <div className="content">
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                </Switch>
            </div>
            <Footer />
        </div>
        </Router>
     );
}
 
export default RedWolf;