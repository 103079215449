import MugsList from "../mugs-section/MugsList";

const MugsSection = () => {
    return ( 
        <div className="mugsection">
            <div className="head">
                <h2 className="head">
                    MUGS
                </h2>
            </div>
            <MugsList />
        </div>
     );
}
 
export default MugsSection;
