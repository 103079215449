

const MugSection = ({muglist}) => {
    return ( 
        
                <div className="mug_info">
                    <img src={muglist.img1} alt="" className="mug_img" />
                    <p className="mug_title">{muglist.title}</p>
                    <hr />
                    <p className="mug_price">Price: {muglist.price}</p>
                </div>
          
           
     );
}
 
export default MugSection;