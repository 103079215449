import Carousel from 'react-bootstrap/Carousel'


const CarouselSection = () => {
    return ( 

        <Carousel>
          <Carousel.Item interval={1500}>
            <img
              className="d-block w-100"
              src="https://www.redwolf.in/image/cache/catalog/sliders/spider-man-no-way-home-slider-1920-1920x450.jpg"
              alt="First slide"
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img
              className="d-block w-100"
              src="https://www.redwolf.in/image/cache/catalog/sliders/super-suit-tees-slider-1920-1920x450.jpg"
              alt="Second slide"
            />
            {/* <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item interval= {1500}>
            <img
              className="d-block w-100"
                src="https://www.redwolf.in/image/cache/catalog/sliders/redwolf-batman-movie-merchandise-slider-india-1920-1920x450.jpg"
                alt="Third slide"
              />
              {/* <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img
              className="d-block w-100"
              src="https://www.redwolf.in/image/cache/catalog/sliders/rick-and-morty-slider-1-1920-1920x450.jpg"
              alt="First slide"
            />
            {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>

     );
}
 
export default CarouselSection;