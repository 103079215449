import React from "react";
import { Link } from "react-router-dom";

function DropList({droplist}){
    return (
        
        <div className="drophead">
            {droplist.drophead} 
            <i className="fas fa-chevron-down down"></i>
        
        <div className="submenu">
            <div className="dropsections">
                <div className="sec">
                    <h1 className="head">
                        {droplist.head}
                    </h1>
                    <li>
                        <Link to="/">{droplist.item1}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item2}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item3}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item4}</Link>
                    </li>
                </div>
                <div className="sec">
                    <h1 className="head">
                        {droplist.head}
                    </h1>
                    <li>
                        <Link to="/">{droplist.item1}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item2}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item3}</Link>
                    </li>
                    <li>
                        <Link to="/">{droplist.item4}</Link>
                    </li>
                </div>
            </div>
        </div>
        </div>
        
    )
}

export default DropList;