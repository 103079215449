import { Link } from "react-router-dom";

const Footer = () => {
    return ( 
        <section className="footer">

            <div className="redstripe">
                <p>HOMEGROWN NEPALI BRAND</p>
            </div>
            <p className="customercount">
                Over <span>1 Million</span> Happy Customers
            </p>

            <div className="foot">
                <div className="footer-links">
                    
                    <div className="link">
                        <div className="links">
                            <h3>NEED HELP</h3>
                            <Link to="/contact">Contact Us</Link>
                            <a href="">Track Order</a>
                            <a href="">FAQs</a>
                            <a href="">My Account</a>
                        </div>
                        <div className="links">
                            <h3>COMPANY</h3>
                            <a href="">About Us</a>
                            <a href="">Careers</a>
                            <a href="">Gift Voucher</a>
                            <a href="">Red Wolf Army</a>
                        </div>
                        <div className="links">
                            <h3>MORE INFO</h3>
                            <a href="">Terms & Conditions</a>
                            <a href="">Privacy Policy</a>
                        </div>  
                    </div> 

                    <div className="followus">
                        <p>Follow Us:</p>
                        <a href=""><i className="fab fa-facebook fb"></i></a>
                        <a href=""><i className="fab fa-instagram in"></i></a>
                        <a href=""><i className="fab fa-twitter tw"></i></a>
                    </div>

                </div>
                <div>
                    <hr/>
                </div>
                <div className="end">
                    <p>© The RedWolf Store. All rights reserved. 
                        Designed by <a href="https://www.instagram.com/saymon.acharya/">Saymon Acharya</a>.
                    </p>
                </div>
            </div>
            
        </section>
     );
}
 
export default Footer;