
import CarouselSection from "./CarouselSection";
import FeaturedThemes from "./FeaturedThemes";
import NewArrivals from "./NewArrivals";
import MugsSection from "./MugsSection";

const Home = () => {
    return ( 
        <div className="home">

          <CarouselSection />
          <FeaturedThemes />
          <NewArrivals />
          <MugsSection />
                
        </div>
     );
}
 
export default Home;