import React from "react";
import MugSection from "./MugSection";

function MugsList(){

    const mugslist = [
        {
            id: 1,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 350,
            display_price: 300
        },
        {
            id: 2,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rather-be-on-the-web-mug-india-438x438.jpg",
            title: "Rather Be On The Web",
            price: 400,
            display_price: 350
        },
        {
            id: 3,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/frenemies-forever-mug-india-438x438.jpg",
            title: "Frenemies Forever - Tom & Jerry Official",
            price: 400,
            display_price: 350
        },
        {
            id: 4,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/game-of-thrones-khaleesi-coffee-mug-india-front-438x438.jpg",
            title: "Khaleesi - Game Of Thrones Official",
            price: 400,
            display_price: 350
        },
        {
            id: 5,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/batman-batsuit-mug-india-438x438.jpg",
            title: "Batsuit - Batman Official Mug",
            price: 400,
            display_price: 350
        },
        {
            id: 6,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/dragon-ball-z-super-saiyan-goku-coffee-mug-india-438x438.jpg",
            title: "Super Saiyan Goku - Dragon Ball Z",
            price: 400,
            display_price: 350
        },
        {
            id: 7,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/game-of-thrones-beyond-the-wall-mug-india-438x438.jpg",
            title: "Beyond The Wall - Game Of Thrones",
            price: 400,
            display_price: 350
        },
        {
            id: 8,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/game-of-thrones-i-drink-and-i-know-things-red-coffee-mug-india-front-438x438.jpg",
            title: "I Drink And I Know Things: Red",
            price: 400,
            display_price: 350
        },
        {
            id: 9,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 10,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 11,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 12,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 13,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 14,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 15,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 16,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 17,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 18,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 19,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 20,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 21,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 22,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 23,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 24,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 25,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 26,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 27,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 28,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 29,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 30,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 31,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 32,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 33,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 34,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 35,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 36,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 37,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 38,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 39,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 40,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 41,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 42,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 43,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 44,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 45,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 46,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 47,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 48,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 49,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        },
        {
            id: 50,
            img1: "https://www.redwolf.in/image/cache/catalog/mugs/rick-and-morty-look-morty-mug-india-front-438x438.jpg",
            title: "Look Morty - Rick And Morty Official",
            price: 400,
            display_price: 350
        }
    ]

    const mugsList = mugslist.slice(0,8).map(muglist =><MugSection key={muglist.id} muglist={muglist} />)

    return <div className="mug_items">{mugsList}</div>;
}

export default MugsList;