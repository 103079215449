import React, { useEffect, useState } from "react";
import './login.css';

function Login(props) {

    const [buttonLogin, setButtonLogin] = useState(false);

    const initialValues = { email:"", password:"" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const[isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    };

    useEffect(() => {
        console.log(formErrors);
        if(Object.keys(formErrors).length === 0 && isSubmit){
            console.log(formValues);
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!values.email) {
            errors.email = "Email is required!";
        } else if(!regex.test(values.email)){
            errors.email = "This email is not valid!";
        }
        if(!values.password) {
            errors.password = "Password is required!";
        } else if(values.password.length < 5){
            errors.password = "Password must be more than 5 characters!";
        } else if(values.password.length > 10 ){
            errors.password = "Password must not exceed 10 characters!";
        }
        return errors;
    }

    return (props.trigger) ? ( 

        <div className="login">

            <div className="main">
                <div className="nav">
                    <div className="login_register">
                        <button className="logregbtn"
                        onClick={() => setButtonLogin(true)}>LOGIN</button>
                        <button className="logregbtn">REGISTER</button>
                    </div>
                    <div className="close">
                        <button className="closebtn" 
                        onClick={() => props.setTrigger(false)}> 
                        <i className="fas fa-times-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="loginpage">
                    <p> Login with The RedWolf Store </p>

                    <div className="formsection">
                        <form onSubmit={handleSubmit}>
                            <div className="field">
                                <div className="inputgroup">
                                    <label htmlFor="email" className="labels">Email</label>
                                    <input type="email" name="email" placeholder="Email" className="loginputs"
                                    value={ formValues.email }
                                    onChange={handleChange} />
                                </div>
                                <p className="error_message">{ formErrors.email }</p>
                            </div>
                            

                            <div className="field">
                                <div className="inputgroup">
                                    <label htmlFor="password" className="labels">Password</label>
                                    <input type="pasword" name="password" placeholder="Password" className="loginputs" 
                                    value={ formValues.password } 
                                    onChange={handleChange} />
                                </div>
                                <p className="error_message">{ formErrors.password }</p>
                            </div> 
                            

                            <button className="loginbtn">Login</button>
                        </form>
                    </div>   

                    <div className="sociallogin">
                        <div className="or">
                            <hr />
                            <span>OR</span>
                            <hr />
                        </div>

                        <div className="social">
                            <p>Sign in with a social account</p>
                        
                        </div>    
                    </div>

                </div>

                {Object.keys(formErrors).length === 0 && isSubmit ? (
                <div className="message_success">
                    Signed in Successfully.
                </div>): (
                    <div className="non">Designed by Saymon Acharya</div>
                )}

            </div>
        </div>

     ) : "";
}
 
export default Login;